import $ from "jquery/dist/jquery";
var playerVimeo = null;

function ajaxReporting(percent,customfunction){
    var contentId = $('.js-analyse-data').attr('data-content');
    var rp        = $('.js-analyse-data').attr('data-rp');
    $.ajax({
        url:"/data/analysis/reviewprogram",
        data:"content="+contentId+"&percent="+percent+"&rp="+rp,
        success:customfunction
    })
}
$(document).ready(function(){

    if($('[data-vimeo-id],[data-vimeo-url]').length!=0) {
        playerVimeo = new Vimeo.Player('handstick');

        playerVimeo.on('timeupdate',function(duration) {
            if((duration.percent*100)>30 && !$('[data-vimeo-id],[data-vimeo-url]').hasClass('sended-report')){
                ajaxReporting(duration.percent*100,function(){
                    $('[data-vimeo-id],[data-vimeo-url]').addClass('sended-report')
                })
            }
        })


    }
})
$(document).on('click','.menu-top-qcm-next-question',function(e){
    e.preventDefault();
    var href      = $(this).attr('href');
    var contentId = $('.js-analyse-data').attr('data-content');
    var rp        = $('.js-analyse-data').attr('data-rp');

    if($('.js-video-data').length!=0){
       if(playerVimeo != null){
           playerVimeo.getDuration().then(function(duration) {
               // duration = the duration of the video in seconds
               playerVimeo.getCurrentTime().then(function(seconds) {
                   // seconds = the current playback position
                   var timePlayer = (seconds/duration)*100;
                   if(timePlayer>30){
                        $.ajax({
                            url:"/data/analysis/reviewprogram",
                            data:"content="+contentId+"&percent="+timePlayer+"&rp="+rp,
                            success:function(){
                                window.location = href;
                            }
                        })
                   }else{
                       window.location = href;
                   }

               }).catch(function(error) {
                   console.log(error);
               });
           }).catch(function(error) {
               // an error occurred
           });
       }else{
           if($('#iframe-kumullus').length!=0){
               $.ajax({
                   url:"/data/analysis/reviewprogram",
                   data:"content="+contentId+"&percent=100&rp="+rp,
                   success:function(){
                       window.location = href;
                   }
               })
            }
       }
    }else{
        if($('#template-media-container .qcm-questions').length!=0){
            //Enregistrer le point du QCM
            window.location = href;
        }

        window.location = href;
    }


})

function qcmDataAnalysis(){
    if($('.qcm-navigation').find('.recordData').length==0){
        var nbrChild = $('.qcm-navigation').children().length;
        var currentPosition = $('.qcm-navigation').find('.active').last().index()+1
        if((currentPosition/nbrChild*100)>60){

            var contentId = $('.js-analyse-data').attr('data-content');
            var rp        = $('.js-analyse-data').attr('data-rp');
            $.ajax({
                url:"/data/analysis/reviewprogram",
                data:"content="+contentId+"&percent="+(currentPosition/nbrChild*100)+"&rp="+rp,
                success:function(){
                    $('.qcm-navigation').find('.active').last().addClass('recordData')
                }
            })
        }
    }
}

function exoDataAnalysis(){
    if(!$("#template-media-container").hasClass('js-recordData-end')){
        var nbrButCorrectinon =  $('.js-display-correction').length;
        var nbrClickButCorrection = $('.js-display-correction.js-data-analysis').length;
        // if((nbrClickButCorrection/nbrButCorrectinon)*100>60){
            var percent = (nbrClickButCorrection/nbrButCorrectinon)*100;
            var contentId = $('.js-analyse-data').attr('data-content');
            var rp        = $('.js-analyse-data').attr('data-rp');
            $.ajax({
                url:"/data/analysis/reviewprogram",
                data:"content="+contentId+"&percent="+percent+"&rp="+rp,
                success:function(){
                    $("#template-media-container").addClass('js-recordData-end')
                }
            })
        // }
    }

}

window.exoDataAnalysis = exoDataAnalysis;
window.qcmDataAnalysis = qcmDataAnalysis;
