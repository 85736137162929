/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */


import $ from "jquery/dist/jquery";
global.$ = $;
global.jQuery = $;

window.$ = $;
window.jQuery = $;

import html2canvas from "html2canvas";
window.html2canvas = html2canvas;
global.html2canvas = html2canvas;

// any CSS you import will output into a single css file (app.css in this case)
import './sass/dashboard.scss';

import 'bootstrap/js/dist/tooltip.js';
import 'bootstrap/js/dist/popover.js';
import './js/jquery.modal.min.js';
//import Player from '@vimeo/player/dist/player';
//window.Player = Player;
//import "@popperjs/core/dist/cjs/popper.js";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './js/data.analysis';
import Chart from 'chart.js/auto/auto';

import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/brands.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';

// start the Stimulus application
//import './bootstrap';
